import { OrganizationId, UserId } from '../../types/idFlavors';

export type User = {
    id: UserId;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export type CreateUser = {
    inviteCode: string;
    password?: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export enum UserRole {
    OWNER = 'owner',
    DEVELOPER = 'developer',
}

export type GetUserRolesResponse = {
    organizationId: OrganizationId;
    organizationName: string;
    role: UserRole;
};

export type ValidateRegistrationRequest = {
    email: string;
    inviteCode: string;
};

export type ValidateRegistrationResponse = {
    hasEatonAccount: boolean;
};

export type UpdateUser = {
    id: UserId;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    company?: string;
};

export type ChangePassword = {
    id: UserId;
    currentPassword: string;
    newPassword: string;
};

export type IUserService = {
    getUser: (token: string) => Promise<User>;
    registerUser: (args: CreateUser) => Promise<User>;
    getUserRoles: (userId: UserId) => Promise<GetUserRolesResponse[]>;
    validateRegistration: (args: ValidateRegistrationRequest) => Promise<ValidateRegistrationResponse>;
    updateUser: (args: UpdateUser) => Promise<User>;
    changePassword: (args: ChangePassword) => Promise<void>;
};
