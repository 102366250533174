import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Group } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../lib/AlertContext';
import { OrganizationService } from '../../services/organization/organization.service';
import {
    AssignOrganizationRoleToUserArgs,
    GetUsersInOrganization,
    Organization,
    RemoveOrganizationUserArgs,
} from '../../services/organization/types';
import { UserRole } from '../../services/user/types';
import { AddUserModal } from './AddUserModal';
import { UsersListItem } from './UsersListItem';
import { Logger } from '../../logger';

export const styles = {
    bar: {
        minHeight: '72px',
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
    },
    usersBox: { zIndex: 2 },
    rightComponent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '30%',
        minHeight: 'inherit',
    },
    groupIcon: { marginLeft: '10px', color: Colors.gray[300] },
    userCount: { marginLeft: '25px' },
    button: { textTransform: 'uppercase' },
};

type UsersListProps = {
    users: GetUsersInOrganization[];
    organization: Organization;
    isUserOwner: boolean;
};

export const UsersList: React.FC<UsersListProps> = (props) => {
    const { isUserOwner, organization } = props;
    const { t } = useTranslation();
    const { showConfirmationToUser, showErrorToUser } = useAlert();

    const [users, setUsers] = useState(props.users);

    const sorted = _.sortBy(users, ['firstName', 'lastName']);

    const [selectedUser, setSelectedUser] = useState<GetUsersInOrganization | null>(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateUsersList = async (): Promise<void> => {
        const updatedUsers = await OrganizationService.getUsersInOrganization(organization.id);
        setUsers(updatedUsers);
    };

    const handleAddUserToOrg = async (userEmail: string, role: UserRole): Promise<void> => {
        try {
            setLoading(true);

            const body = {
                organizationId: organization.id,
                email: userEmail,
                role,
            };

            await OrganizationService.assignOrganizationRoleToUser(body);

            await updateUsersList();

            setLoading(false);
            setShowAddUserModal(false);
        } catch (e) {
            setLoading(false);
            setShowAddUserModal(false);
            showErrorToUser({
                errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_TO_ORG_TITLE'),
                errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_TO_ORG_BODY'),
                confirmText: t('COMMON.TRY_AGAIN'),
                cancelText: t('COMMON.CANCEL'),
                onConfirm: () => {
                    setShowAddUserModal(true);
                },
                onCancel: () => {
                    setShowAddUserModal(false);
                },
            });
        }
    };

    const handleChangeUserRole = async (): Promise<void> => {
        if (!selectedUser) {
            Logger.error('Error when attempting to update user role, selectedUser does not exist');
            return;
        }

        const body: AssignOrganizationRoleToUserArgs = {
            organizationId: organization.id,
            role: selectedUser.role === UserRole.DEVELOPER ? UserRole.OWNER : UserRole.DEVELOPER,
            userId: selectedUser?.id,
        };

        await OrganizationService.assignOrganizationRoleToUser(body);
        await updateUsersList();
    };

    const renderChangeUserRoleModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_TITLE', {
                newRole: selectedUser?.role === UserRole.DEVELOPER ? 'Owner' : 'Developer',
            }),
            description: (
                <>
                    {selectedUser?.role === UserRole.DEVELOPER
                        ? t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_OWNER_BODY', {
                              firstName: selectedUser?.firstName,
                              lastName: selectedUser?.lastName,
                          })
                        : t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_DEV_BODY', {
                              firstName: selectedUser?.firstName,
                              lastName: selectedUser?.lastName,
                          })}
                    <br />
                    <br />
                    {t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_QUESTION')}
                </>
            ),
            testId: 'change-user-role-modal',
            confirmText: t('COMMON.UPDATE'),
            cancelText: t('COMMON.CANCEL'),
            onConfirm: handleChangeUserRole,
            errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_ROLE_TO_ORG_TITLE'),
            errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_ROLE_TO_ORG_BODY', {
                role: selectedUser?.role === UserRole.DEVELOPER ? t('COMMON.OWNER') : t('COMMON.DEVELOPER'),
            }),
        });
    };

    const handleRemoveUser = async (): Promise<void> => {
        if (!selectedUser) {
            throw new Error('Selected user not found');
        }

        const body: RemoveOrganizationUserArgs = {
            organizationId: organization.id,
            userId: selectedUser.id,
        };

        await OrganizationService.removeOrganizationUser(body);
        await updateUsersList();
    };

    const renderRemoveUserModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MENU_ITEM'),
            description: (
                <>
                    {t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MODAL_BODY', {
                        firstName: selectedUser?.firstName,
                        lastName: selectedUser?.lastName,
                    })}
                </>
            ),
            confirmText: t('COMMON.REMOVE'),
            cancelText: t('COMMON.CANCEL'),
            testId: 'remove-user-modal',
            onConfirm: handleRemoveUser,
            errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REMOVE_USER_TO_ORG_TITLE'),
            errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REMOVE_USER_TO_ORG_BODY'),
        });
    };

    const getMenuItems = (role: UserRole): Array<{ testid: string; text: string; onClick: () => void }> => [
        {
            testid: role === UserRole.DEVELOPER ? 'make-owner' : 'make-developer',
            text:
                role === UserRole.DEVELOPER
                    ? t('ORGANIZATION_DETAILS_PAGE.MAKE_USER_OWNER_MENU_ITEM')
                    : t('ORGANIZATION_DETAILS_PAGE.MAKE_USER_DEVELOPER_MENU_ITEM'),
            onClick: (): void => renderChangeUserRoleModal(),
        },
        {
            testid: 'remove-user',
            text: t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MENU_ITEM'),
            onClick: (): void => renderRemoveUserModal(),
        },
    ];

    return (
        <Box data-testid="users-list">
            <InfoListItem
                sx={styles.bar}
                title={
                    <Typography variant="h5">{t('ORGANIZATION_DETAILS_PAGE.USERS_ASSIGNED_TO_ORG_TITLE')}</Typography>
                }
                data-testid="list-header"
                avatar={false}
                hidePadding={true}
                divider={'full'}
                wrapTitle={true}
                rightComponent={
                    <>
                        {isUserOwner && (
                            <Box sx={styles.rightComponent}>
                                <Button
                                    data-testid={'add-user-button'}
                                    sx={styles.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(): void => {
                                        setShowAddUserModal(true);
                                    }}
                                >
                                    {t('ORGANIZATION_DETAILS_PAGE.ADD_USER_BUTTON')}
                                </Button>
                            </Box>
                        )}
                        <Typography data-testid="user-count" sx={styles.userCount} variant="body1">
                            {Object.keys(users).length}
                        </Typography>
                        <Group sx={styles.groupIcon} />
                    </>
                }
            />
            {sorted.map((user, index) => (
                <UsersListItem
                    key={index}
                    user={user}
                    itemLabel={user.role}
                    userMenuItems={getMenuItems(user.role)}
                    isUserOwner={isUserOwner}
                    showUserMenu={selectedUser === user}
                    setShowUserMenu={setSelectedUser}
                />
            ))}
            {showAddUserModal && (
                <AddUserModal
                    handleAddUserToOrg={handleAddUserToOrg}
                    setShowAddUserModal={setShowAddUserModal}
                    loading={loading}
                />
            )}
        </Box>
    );
};
