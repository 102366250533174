import * as Colors from '@brightlayer-ui/colors';
import { Tokens } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/ableedgedevportal.png';
import backgroundImage from '../../assets/background.svg';
import { Logger } from '../../logger';
import { getOktaConfig } from '../../oktaConfig';

import { Spinner } from '@brightlayer-ui/react-auth-workflow';
import { useNavigate } from 'react-router';
import './Login.css';

type LoginProps = {
    onSuccessfulLogin: (tokens: Tokens) => Promise<void>;
};

export const Login: React.FC<LoginProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const widgetRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get('state') ?? undefined;

    useEffect(() => {
        if (!widgetRef.current) {
            return;
        }

        const { issuer, clientId, redirectUri, scopes } = getOktaConfig(window);
        const widget = new OktaSignIn({
            /**
             * Note: when using the Sign-In Widget for an OIDC flow, it still
             * needs to be configured with the base URL for your Okta Org. Here
             * we derive it from the given issuer for convenience.
             */
            baseUrl: issuer.split('/oauth2')[0],
            clientId,
            redirectUri,
            logo,
            i18n: {
                en: {
                    'primaryauth.username.placeholder': t('LOGIN_PAGE.EMAIL_ADDRESS'),
                    'oie.remember': t('LOGIN_PAGE.REMEMBER_ME'),
                    'oie.primaryauth.submit': t('LOGIN_PAGE.LOG_IN'),
                    forgotpassword: t('LOGIN_PAGE.FORGOT_PASSWORD'),
                },
            },
            authParams: {
                issuer,
                scopes,
            },
            useInteractionCodeFlow: true,
            state,
            colors: {
                brand: Colors.blue[500],
            },
            customButtons: [
                {
                    title: 'Create Account',
                    className: 'create-account-button',
                    click: (): void => {
                        navigate('/register/create-account');
                    },
                },
            ],
        });

        widget
            .renderEl(
                { el: widgetRef.current },
                (res) => {
                    if (res.status === 'SUCCESS') {
                        if (res.tokens) {
                            props.onSuccessfulLogin(res.tokens).catch(() => {
                                Logger.error('Error handling successful login');
                            });
                        }

                        oktaAuth.handleLoginRedirect(res.tokens).catch(() => {
                            Logger.error('Error handling login redirect');
                        });
                    }
                },
                (err) => {
                    setIsLoading(false);
                    throw err;
                }
            )
            .catch(() => {
                setIsLoading(false);
                Logger.error('Error rendering Okta login widget');
            });

        // Have to do the styling here instead of on the div due to weirdness from the Okta widget
        const documentRoot = document.documentElement;

        documentRoot.style.setProperty('--primary-color', Colors.blue[500]);

        document.body.style.backgroundColor = Colors.blue[500];
        document.body.style.backgroundImage = `url(${backgroundImage})`;

        widget.on('afterRender', () => {
            setIsLoading(false);
        });

        return (): void => {
            widget.remove();

            // Clear the page styling when we leave
            document.body.style.backgroundColor = '';
            document.body.style.backgroundImage = '';
        };
    }, [oktaAuth]);

    return (
        <>
            <Spinner visible={isLoading} />
            <div ref={widgetRef as any} />
        </>
    );
};
